<template>
	<div id="ask" :style="{'background-color': bgColor}">
		<v-head currentIndex=2></v-head>
		<div class="ask-bannel" v-if="showBannel">
            <div class="search-container">
                <div class="search-box">
                    <a-dropdown :trigger="['click']">
                        <div class="aq-cc-value" :data-value="categoryValue">{{categoryName || "科別"}}</div>
                        <a-menu slot="overlay">
                            <a-menu-item @click="onCategoryItemClick('', '')" key="-1">科別</a-menu-item>
                            <a-menu-divider />
                            <a-menu-item @click="onCategoryItemClick(category.codedesc, category.codeid)" :key="category.codeid" v-for="category in categorys">
                                {{category.codedesc}}
                            </a-menu-item>
                        </a-menu>
                    </a-dropdown>
                    <input type="text" class="aq-kw" v-model="keyword" placeholder="輸入關鍵字,例如：失眠，心悸，腹痛…" @keyup="onKeyEnter"/>
                    <div class="aq-search" @click="doSearch">搜索解答</div>
                </div>
                <div class="search-hot-kws">
                    <span>熱門搜索：</span>
                    <ul class="hot-kws">
                        <li class="hot-kw"><router-link to="/ask/list?keyword=感冒">感冒</router-link>、</li>
                        <li class="hot-kw"><router-link to="/ask/list?keyword=高烧">高烧</router-link>、</li>
                        <li class="hot-kw"><router-link to="/ask/list?keyword=風濕">風濕</router-link>、</li>
                        <li class="hot-kw"><router-link to="/ask/list?keyword=咳嗽">咳嗽</router-link>、</li>
                        <li class="hot-kw"><router-link to="/ask/list?keyword=針灸">針灸</router-link>、</li>
                        <li class="hot-kw"><router-link to="/ask/list?keyword=兒科">兒科</router-link>、</li>
                        <li class="hot-kw"><router-link to="/ask/list?keyword=月經不調">月經不調</router-link>、</li>
                        <li class="hot-kw"><router-link to="/ask/list?keyword=消化不良">消化不良</router-link></li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="ask-content">
            <router-view class="router-view" :kk="params"></router-view>
            <div class="ask-frameset-r">
                <div class="ask-c-stars" v-if="showStarsItem1 && categorys.length">
                    <h3 class="ask-c-stars-head">分科問答</h3>
                    <dl class="ask-c-stars-list">
                        <dd class="ask-c-stars-item" v-for="(category, i) in categorys" :key="category.codeid">
                            <span :data-v="i+1" v-text="i<3?'': i+1"></span>
                            <router-link :to="'/ask/list?IllCate='+category.codeid" v-text="category.codedesc"></router-link>
                        </dd>
                    </dl>
                </div>
                <div class="ask-d-stars" v-if="showStarsItem2 && topDoctors.length">
                    <h3 class="ask-d-stars-head">醫師問答之星</h3>
                    <dl class="ask-d-stars-list">
                        <dd class="ask-d-stars-item" v-for="(doctor, index) in topDoctors" :key="index"><span :data-v="index+1" v-text="index < 3 ? '' : index+1"></span><router-link :to="'/doctor/'+doctor.globaluserid+'/ask'" :title="doctor.username" v-text="doctor.username"></router-link></dd>
                    </dl>
                </div>
                <div class="idfa" v-if="showIdfa">
                    <div class="app-info">
                        <span class="app-name">【大醫APP】</span>
                        <span class="app-desc">隨時隨地咨詢醫師，查看健康資訊</span>
                        <div class="app-qrcode-container">
                            <div class="app-qrcode">
                                <img src="../../../assets/imgs/qr_code.png" alt="">
                                <span class="qrcode-title">掃碼下載大醫App</span>
                            </div>
                        </div>
                    </div>
                    <div class="web-intro">
                        <div class="fr-link-list">
                            <a class="fr-link-item" v-for="item in this.constants.friendlyLinks" :href="item.url" target="_blank" v-text="item.title"></a>
                        </div>
                        <div class="safe-icos">
                            <a href="javascript:;" data-url="https://seal.godaddy.com/verifySeal?sealID=H1ahlwwmjgaLEmPXgaSipSp5JvRqE5ESwDOgW4qjVZ4kYfENswwF51bdQiuz" class="safe-icon-link"><i class="icon-safe icon1"></i></a>
                            <a href="javascript:;" class="safe-icon-link"><i class="icon-safe icon2"></i></a>
                            <a href="https://www.mcafeesecure.com/verify?host=ectcm.com&popup=1" target="_blank" class="safe-icon-link"><i class="icon-safe icon3"></i></a>
                            <a href="javascript:;" class="safe-icon-link"><i class="icon-safe icon4"></i></a>
                        </div>
                        <div class="about-us-list">
                            <a class="about-us-item" href="/home/about">關於我們</a>
                            <a class="about-us-item feedback" href="/home/feedback">用戶反饋</a>
                            <a class="about-us-item" href="/home/disclaimer">使用條款及免責聲明</a>
                            <a class="about-us-item" href="/home/privacy">私隱政策</a>
                            <a class="about-us-item" href="/home/contact">聯絡我們</a>
                        </div>
                        <router-link class="link-protocol" to="/home/disclaimer">使用條款及免責聲明</router-link>
                        <div class="copyright">Copyright ©{{new Date().getFullYear()}} 版權所有<br/>深圳本草通网络有限公司<br/>Shenzhen Bencaotong Network Co., Ltd. </div>
                    </div>
                </div>
            </div>
        </div>
        <v-foot class="foot" v-if="showFoot"></v-foot>  
	</div>
</template>

<script>
    import vHead from '@/layouts/Head.vue'
    import vFoot from '@/layouts/Foot.vue'
    import Loading from '@/layouts/Loading.vue'
	export default {
        components: {
            vHead,
            vFoot,
            Loading,
        },
        data(){
            return {
                keyword: '',
                params: null,
                bgColor: '#00000000',
                showStarsItem1: true,
                showStarsItem2: true,
                showBannel: false,
                showIdfa: true,
                showFoot: false,
                categoryValue: '',
                categoryName: '',
                categorys: this.$store.getters.askCategorys || [],
                topDoctors: this.$store.getters.topDoctors || [],
                loadingCategory: false,
            }
        },
        methods: {
            onKeyEnter: function(k) {
                if(k.key === 'Enter') {
                    this.doSearch()
                }
            },
            doSearch: function() {
                this.params = null
                setTimeout(() => {
                    this.params = {keyword: this.keyword, categoryid: this.categoryValue}
                }, 10)
            },
            changeGender: function(v) {
                this.gender = v
            },
            onCategoryItemClick: function(name, value) {
                this.categoryName = name
                this.categoryValue = value
            },
        },
        mounted: function(){
            var path = this.$route.path
            if(path.startsWith('/ask/postnew')) {
                this.showFoot = true
                this.showIdfa = false
            }
            if(path == '/ask/list') {
                this.showBannel = true
            } else {
                this.showBannel = false
                if(path.startsWith('/ask/detail/')) {
                    this.showStarsItem1 = false
                    this.showStarsItem2 = false
                    this.bgColor = '#F7F7F7'
                }
            }
            
        },
        created: function(){
            if(!this.categorys.length) {
                this.$store.dispatch('getAskCategorys', {'langcode': 'B5'})
                .then((rs)=>{
                    this.categorys = rs || []
                })
            }
            if(!this.topDoctors.length) {
                this.$store.dispatch('getTopAskDoctors', {})
                .then((list) => {
                    this.topDoctors = list || []
                })
            }
        }, 
        watch: {
            $route: function(to, from) {
                var path = to.path
                if(path.startsWith('/ask/postnew')) {
                    this.showFoot = true
                    this.showIdfa = false
                }
                if(path == '/ask/list') {
                    this.showBannel = true
                } else {
                    this.showBannel = false
                    if(path.startsWith('/ask/detail/')) {
                        this.showStarsItem1 = false
                        this.showStarsItem2 = false
                        this.bgColor = '#F7F7F7'
                    }
                }
            },
            '$route.query.keyword': function(keyword){
                this.keyword = keyword
            },
        }
    }
</script>

<style lang="scss" scoped>
   #ask {
	   width: 100%;
   }

   .ask-bannel {
        width: 100%;
        height: 202px;
        background-size: 100% 100%;
        background-image: url(../../../assets/imgs/pc/img_banner@2x.png);
        background-repeat: no-repeat;
    }

    .ask-bannel .search-container {
        position: relative;
        height: 202px;
        width: 1000px;
        margin: 0px auto;
    }

    .ask-bannel .search-container > .search-box {
        height: 36px;
        line-height: 36px;
        width: 508px;
        position: relative;
        display: flex;
        background-color: #fff;
        border-radius: 8px;
        position: absolute;
        left: 246px;
        top: 74px;
    }

    .ask-bannel .search-container > .search-hot-kws {
        display: flex;
        flex-direction: row;
        justify-content: center;
        position: absolute;
        left: 0;
        right: 0;
        top: 135px;
        font-weight: 400;
        color: #fff;
        font-size: 14px;
        line-height: 13px;
    }
    
    .ask-bannel .search-container > .search-hot-kws > .hot-kws {
        display: flex;
        flex-direction: row;
    }
    
    .ask-bannel .search-container > .search-hot-kws > .hot-kws > .hot-kw {
        margin-right: 5px;
    }

    .ask-bannel .search-container > .search-box > .aq-cc-value  {
        padding-left: 17px;
        padding-right: 31px;
        position: relative;
        border: 1px solid #2B99A6;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        cursor: pointer;
        box-sizing: border-box;
        font-size: 16px;
        font-weight: bold;
        color: #231F20;
    }

    .ask-bannel .search-container > .search-box > .aq-cc-value::after {
        content: '';
        position: absolute;
        right: 11px;
        top: 50%;
        width: 10px;
        height: 7px;
        transform: translateY(-50%);
        background-image: url(../../../assets/imgs/pc/img_xial2@2x.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
    }

    .ask-bannel .search-container > .search-box > .aq-kw  {
        flex: 1;
        padding-left: 9px;
        line-height: 36px;
        border-top: 1px solid #2B99A6;
        border-bottom: 1px solid #2B99A6;
        box-sizing: border-box;
        font-weight: bold;
        color: #231F20;
        font-size: 16px;
    }

    .ask-bannel .search-container > .search-box > .aq-search  {
        width: 78px;
        line-height: 36px;
        background-color: #36C4D0;
        color: #fff;
        font-size: 16px;
        height: 36px;
        text-align: center;
        font-weight: bold;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        border: 1px solid #36C4D0;
        box-sizing: border-box;
        cursor: pointer;
    }

    .ask-content {
        width: 1000px;
        margin: 0 auto;
        display: flex;
        flex-direction: row;
        margin-top: 10px;
    }

    .ask-content > .router-view {
        flex: 1;
    }

    .ask-frameset-r {
        width: 298px;
        display: flex;
        flex-direction: column;
        margin-left: 10px;
    }

    .ask-frameset-r > .ask-c-stars {
        padding: 10px 0px 14px 16px;
        background-color: #fff;
        position: relative;
        width: 298px;
        box-sizing: border-box;
        overflow: hidden;
    }

    .ask-frameset-r > .ask-c-stars > .ask-c-stars-head {
        position: absolute;
        left: 0px;
        top: 0px;
        height: 38px;
        width: 298px;
        line-height: 38px;
        border-bottom: 1px solid #E8E8E8;
        color: #000;
        font-weight: bold;
        padding-left: 40px;
        font-size: 16px;
    }

    .ask-frameset-r > .ask-c-stars > .ask-c-stars-head::before {
        content: '';
        position: absolute;
        left: 16px;
        top: 10px;
        width: 16px;
        height: 16px;
        background-image: url(../../../assets/imgs/pc/img_fenlei@2x.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
    }

    .ask-frameset-r > .ask-c-stars > .ask-c-stars-list {
        display: grid;
        grid-template-columns: 94px 94px 94px;
        grid-template-rows: 37px 37px 37px 37px;
        margin-top: 39px;
        grid-auto-flow: column;
    }

    .ask-frameset-r > .ask-c-stars > .ask-c-stars-list > .ask-c-stars-item {
        display: inline-block;
        height: 37px;
        line-height: 37px;
        text-align: left;
        position: relative;
        padding-left: 28px;
        font-size: 16px;
        font-weight: 400;
        color: #636363;
    }

    .ask-frameset-r > .ask-c-stars > .ask-c-stars-list > .ask-c-stars-item:nth-of-type(n+4) {
        padding-left: 27px;
    }

    .ask-frameset-r > .ask-c-stars > .ask-c-stars-list > .ask-c-stars-item > span {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        text-align: center;
        line-height: 16px;
        background-color: #DCF7FB;
        font-weight: 400;
        color: #2B99A6;
        font-size: 12px;
        position: absolute;
        left: 0px;
        top: 50%;
        transform: translateY(-50%);
    }

    .ask-frameset-r > .ask-c-stars > .ask-c-stars-list > .ask-c-stars-item > span[data-v='1'],
    .ask-frameset-r > .ask-c-stars > .ask-c-stars-list > .ask-c-stars-item > span[data-v='2'],
    .ask-frameset-r > .ask-c-stars > .ask-c-stars-list > .ask-c-stars-item > span[data-v='3'] {
        width: 17px;
        height: 21px;
        top: 18px;
        border-radius: unset;
        background-color: transparent;
        background-image: url(../../../assets/imgs/pc/img_one@2x.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
    }

    .ask-frameset-r > .ask-c-stars > .ask-c-stars-list > .ask-c-stars-item > span[data-v='2'] {
        background-image: url(../../../assets/imgs/pc/img_two@2x.png);
    }

    .ask-frameset-r > .ask-c-stars > .ask-c-stars-list > .ask-c-stars-item > span[data-v='3'] {
        background-image: url(../../../assets/imgs/pc/img_three@2x.png);
    }

    .ask-frameset-r > .ask-d-stars {
        width: 298px;
        height: 260px;
        overflow: hidden;
        background-color: #fff;
        margin-top: 10px;
    }

    .ask-frameset-r > .ask-d-stars > .ask-d-stars-head {
        position: relative;
        height: 38px;
        width: 298px;
        line-height: 38px;
        border-bottom: 1px solid #E8E8E8;
        color: #000;
        font-weight: bold;
        padding-left: 40px;
        font-size: 16px;
    }

    .ask-frameset-r > .ask-d-stars > .ask-d-stars-head::before {
        content: '';
        position: absolute;
        left: 16px;
        top: 10px;
        width: 16px;
        height: 16px;
        background-image: url(../../../assets/imgs/pc/img_xing@2x.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
    }

    .ask-frameset-r > .ask-d-stars > .ask-d-stars-list {
        display: grid;
        grid-template-columns: 141px 141px;
        grid-template-rows: 37px 37px 37px 37px 37px;
        grid-auto-flow: column;
        margin: 10px 0px 14px 16px;

    }

    .ask-frameset-r > .ask-d-stars > .ask-d-stars-list > .ask-d-stars-item {
        height: 37px;
        line-height: 37px;
        padding-left: 28px;
        position: relative;
        font-size: 16px;
        font-weight: 400;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }


    .ask-frameset-r > .ask-d-stars > .ask-d-stars-list > .ask-d-stars-item:nth-of-type(n+4) {
        padding-left: 27px;
    }

    .ask-frameset-r > .ask-d-stars > .ask-d-stars-list > .ask-d-stars-item > span {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        text-align: center;
        line-height: 16px;
        background-color: #DCF7FB;
        font-weight: 400;
        color: #2B99A6;
        font-size: 12px;
        position: absolute;
        left: 0px;
        top: 50%;
        transform: translateY(-50%);
    }

    .ask-frameset-r > .ask-d-stars > .ask-d-stars-list > .ask-d-stars-item > span[data-v='1'],
    .ask-frameset-r > .ask-d-stars > .ask-d-stars-list > .ask-d-stars-item > span[data-v='2'],
    .ask-frameset-r > .ask-d-stars > .ask-d-stars-list > .ask-d-stars-item > span[data-v='3'] {
        width: 17px;
        height: 21px;
        top: 18px;
        border-radius: unset;
        background-color: transparent;
        background-image: url(../../../assets/imgs/pc/img_one@2x.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
    }

    .ask-frameset-r > .ask-d-stars > .ask-d-stars-list > .ask-d-stars-item > span[data-v='2'] {
        background-image: url(../../../assets/imgs/pc/img_two@2x.png);
    }

    .ask-frameset-r > .ask-d-stars > .ask-d-stars-list > .ask-d-stars-item > span[data-v='3'] {
        background-image: url(../../../assets/imgs/pc/img_three@2x.png);
    }


    .ask-frameset-r .web-intro {
        width: 298px;
        background-color: #fff;
    }

    .ask-frameset-r .web-intro .fr-link-list {
       display: grid;
        grid-template-columns: 136px 147px;
        grid-template-rows: 34px 34px 34px 34px 34px;
        padding-top: 14px;
        grid-auto-flow: column;
    }

    .ask-frameset-r .web-intro .fr-link-list .fr-link-item {
        line-height: 34px;
        padding-left: 15px;
        font-size: 14px;
        font-weight: 400;
        white-space: nowrap;
    }

    /* app信息 start */
    .ask-frameset-r .app-info {
        background-color: #FFF4EF;
        width: 298px;
        height: 210px;
        display: flex;
        flex-direction: column;
    }

    .ask-frameset-r .app-info > .app-name {
        color: #FF7800;
        font-size: 18px;
        font-weight: bold;
        line-height: 18px;
        margin-left: 16px;
        margin-top: 20px;
    }

    .ask-frameset-r .app-info > .app-desc {
        color: #FF7800;
        font-size: 14px;
        line-height: 14px;
        margin-left: 15px;
        margin-top: 10px;
    }

    .ask-frameset-r .app-info > .app-qrcode-container {
        display: flex;
        flex-direction: row;
    }

    .ask-frameset-r .app-info > .app-qrcode-container .app-qrcode {
        margin: 12px 20px;
        width: 90px;
    }

    .ask-frameset-r .app-info > .app-qrcode-container img {
        width: 100%;
        height: 90px;
        vertical-align: top;
    }

    .ask-frameset-r .app-info > .app-qrcode-container .qrcode-title {
        font-weight: 400;
        color: #231F20;
        font-size: 14px;
        height: 13px;
        white-space: nowrap;
        line-height: 30px;
    }

    /* app信息 end */

    /* 安全图标 start */

    .ask-frameset-r .safe-icos {
        padding-left: 18px;
        padding-top: 10px;
    }

    .ask-frameset-r .safe-icos .safe-icon-link {
        display: inline-block;
        margin-top: 13px;
        height: 32px;
    }

    .ask-frameset-r .safe-icos .safe-icon-link:nth-child(1) .icon-safe {
        display: inline-block;
        width: 131px;
        height: 32px;
        margin-right: 12px;
        background-image: url(../../../assets/imgs/pc/img_ver@2x.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
    }

    .ask-frameset-r .safe-icos .safe-icon-link:nth-child(2) .icon-safe {
        display: inline-block;
        width: 82px;
        height: 32px;
        background-image: url(../../../assets/imgs/pc/img_peiyu@2x.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
    }

    .ask-frameset-r .safe-icos .safe-icon-link:nth-child(3) .icon-safe {
        display: inline-block;
        width: 94px;
        height: 32px;
        margin-right: 8px;
        background-image: url(../../../assets/imgs/pc/img_mc@2x.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
    }


    .ask-frameset-r .safe-icos .safe-icon-link:nth-child(4) .icon-safe {
        display: inline-block;
        width: 86px;
        height: 32px;
        background-image: url(../../../assets/imgs/pc/img_sll@2x.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
    }
    /* 安全图标 end */

    .ask-frameset-r .about-us-list {
        margin-left: 9px;
        margin-top: 30px;
    }

    .ask-frameset-r .about-us-list > .about-us-item {
        padding-left: 6px;
        font-weight: 400;
        line-height: 13px;
        height: 13px;
        font-size: 14px;
        color: #636363;
    }

    .ask-frameset-r .about-us-list > .about-us-item:nth-child(n+2) {
        position: relative;
    }

    .ask-frameset-r .about-us-list > .about-us-item:nth-child(n+2)::before {
        content: '';
        position: absolute;
        left: -1px;
        top: 8px;
        width: 3px;
        height: 3px;
        border-radius: 50%;
        background-color: #636363;
    }

    .ask-frameset-r .link-protocol {
        display: block;
        margin-left: 16px;
        margin-top: 30px;
        font-weight: 400;
        line-height: 13px;
        height: 13px;
        font-size: 14px;
        color: #636363;
    }

    .ask-frameset-r .copyright {
        margin: 7px 0px 39px 16px;
        font-size: 14px;
        color: #636363;
        line-height: 26px;
    }

    .idfa {
        display: flex;
        flex-direction: column;
        position: unset;
        top: 0px;
        left: 0px;
    }

    .idfa.fixed {
        position: fixed;
    }

    .foot-wrapper {
        margin-top: 12px;
        background-color: #fff;
    }

    .foot {
        background-color: #fff;
        margin-top: 12px;
    }


    /** loading start */
    .lds-ring {
        display: inline-block;
        position: relative;
        width: 32px;
        height: 32px;
    }
    
    .lds-ring div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 24px;
        height: 24px;
        margin: 4px;
        border: 4px solid #36C4D0;
        border-radius: 50%;
        animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: #36C4D0 transparent transparent transparent;
    }
    
    .lds-ring div:nth-child(1) {
        animation-delay: -0.45s;
    }
    
    .lds-ring div:nth-child(2) {
        animation-delay: -0.3s;
    }
    
    .lds-ring div:nth-child(3) {
        animation-delay: -0.15s;
    }

    @keyframes lds-ring {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
</style>
